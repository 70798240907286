


















































































import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import { Getter, namespace, State } from 'vuex-class';
import AlertType from '@/utils/enums/AlertType';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import AuthPageGuard from '@/guards/AuthPageGuard';

const authenticationStore = namespace('AuthenticationStore');

/* eslint-disable no-underscore-dangle */
@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        valid(value: string): boolean {
          const containsUppercase = value ? value.match(/[A-Z]/) : false;
          const containsLowercase = value ? value.match(/[a-z]/) : false;
          const containsNumber = value ? value.match(/[0-9]/) : false;
          return !!containsUppercase && !!containsLowercase && !!containsNumber;
        },
      },
      passwordConfirm: {
        required,
        minLength: minLength(8),
      },
      agreed: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInCreatePassword extends Vue {
  @Getter
  private readonly community!: Community;

  @State
  private readonly authUser!: CommunityUser;

  @Prop({
    required: false,
    default: '',
  })
  private token!: string;

  @authenticationStore.Action
  private updatePassword!: (payload: {
    tempToken: string;
    newPassword: string;
    uid: string;
    email?: string;
  }) => Promise<boolean>;

  @authenticationStore.Action
  private activateAccount!: () => Promise<boolean>;

  @authenticationStore.Action
  private logout!: () => Promise<void>;

  private alertType = AlertType;

  private showError = false;

  private passwordError = false;

  private passwordConfirmError = false;

  private serverError = false;

  private userEmail = '';

  private email = '';

  private userUid: string | null = null;

  private form = {
    password: '',
    passwordConfirm: '',
  };

  created(): void {
    if (this.token && this.token !== '') {
      const parsedToken = this.parseJwt();
      if (parsedToken) {
        this.userUid = parsedToken.u ? parsedToken.u : null;
        this.userEmail = parsedToken.e ? parsedToken.e : '';
        this.email = this.userEmail;
      }
    } else if (this.authUser) {
      if (this.authUser._needsPasswordCreated) {
        this.userUid = this.authUser.uid;
        this.email = this.authUser.email as string;
      } else {
        this.$router.replace({ path: '/' });
      }
    } else {
      this.$router.replace({ name: 'sign-in' });
    }
  }

  parseJwt(): { c: string; e: string; exp: number; iat: number; u: string } | null {
    if (this.token && this.token !== '') {
      const base64Url = this.token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+')
        .replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((c) => `%${(`00${c.charCodeAt(0)
          .toString(16)}`).slice(-2)}`)
        .join(''));

      return JSON.parse(jsonPayload);
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange(field: string): void {
    if (this.$v) {
      this.passwordError = this.$v.form.password ? this.$v.form.password.$invalid : false;
      this.passwordConfirmError = !(this.form.password
        && this.form.passwordConfirm
        && this.form.password === this.form.passwordConfirm);
    }
    this.showError = this.passwordError || this.passwordConfirmError;
  }

  private onBlur(): void {
    if (this.showError) {
      this.onChange('');
    }
  }

  private onSubmit(): void {
    this.showError = true;
    this.onChange('agreed');
    if (this.form.passwordConfirm
      && !this.$data.passwordConfirmError
      && this.form.password
      && !this.$data.passwordError
      && this.userUid
    ) {
      this.updatePassword({
        tempToken: this.token,
        newPassword: this.form.password,
        uid: this.userUid,
        email: this.userEmail,
      })
        .then((response) => {
          if (response) {
            if ((this.authUser._needsActivation === null || this.authUser._needsActivation)
              && !this.authUser._needsNameCreated
              && !this.authUser._needsPasswordCreated
              && !this.authUser._needsEmailDisambiguated
            ) {
              this.activateAccount()
                .then((res) => {
                  if (res) {
                    this.$router.replace({ path: '/' });
                  }
                });
            } else {
              this.$router.replace({ path: '/' });
            }
          } else {
            this.serverError = true;
          }
        })
        .catch(() => {
          this.serverError = true;
        });
    }
  }

  private onContinueAsGuest(): void {
    this.logout()
      .then(() => {
        this.$router.replace({ path: '/' });
      });
  }
}
